export default class Form {
    constructor() {
        document.addEventListener("DOMContentLoaded", function (e) {

            /**
            * Form validation
            **/

            // Fetch all the forms we want to apply custom Bootstrap validation styles to
            const forms = document.querySelectorAll('.needs-validation')

            // Loop over them and prevent submission
            Array.from(forms).forEach(form => {

                form.addEventListener('submit', event => {
                    if (!form.checkValidity()) {
                        event.preventDefault()
                        event.stopPropagation()
                    }

                    form.classList.add('was-validated')
                }, false)
            });




            /**
            * Show or hide fields based on the selected radio button.
            **/

            const radioButtonsPresent = document.getElementsByName('present');
            const radioButtonsParticipants = document.getElementsByName('participants');
            

            radioButtonsPresent.forEach(function (radioButton) {
                radioButton.addEventListener('change', function () {

                    document.querySelectorAll('.present-yes').forEach(function (presentYes) {
                        if (radioButton.value == 'Oui') {
                            presentYes.classList.add('d-block');
                            presentYes.classList.remove('d-none');

                            radioButtonsParticipants.forEach(function (radioButtonsParticipant) {
                                radioButtonsParticipant.setAttribute('required', 'required');
                            });

                        } else if (radioButton.value == 'Non') {
                            presentYes.classList.add('d-none');
                            presentYes.classList.remove('d-block');
-
                            presentYes.querySelectorAll('input').forEach(function (input) {
                                input.removeAttribute('required', 'required');
                            })
                        }
                    })
                
                });
            });


            
            radioButtonsParticipants.forEach(function (radioButton) {
                radioButton.addEventListener('change', function () {
                    if (radioButton.checked) {

                        document.querySelector('.participant-info-wrapper legend').classList.add('d-block');
                        document.querySelector('.participant-info-wrapper legend').classList.remove('d-none');

                        const participantsUnselect = document.querySelectorAll(".participant-infos");
                        participantsUnselect.forEach(function (participantUnselect) {
                            participantUnselect.classList.add('d-none');
                            participantUnselect.classList.remove('d-block');

                            const participantsInput =  participantUnselect.querySelectorAll('input')
                            participantsInput.forEach(function (participantInput) {
                                participantInput.removeAttribute('required', 'required')
                            })
                        })


                        const radioButtonValue = radioButton.value;
                        const participantsSelect = document.querySelectorAll(".participant-infos:nth-child(-n +" + (Number(radioButtonValue) + Number(1)) + ")");

                        participantsSelect.forEach(function (participantSelect) {
                            participantSelect.classList.add('d-block')
                            participantSelect.classList.remove('d-none');

                            const participantsInput =  participantSelect.querySelectorAll('input')
                            participantsInput.forEach(function (participantInput) {
                                participantInput.setAttribute('required', 'required')
                            })
                        })
                    }
                });
            });
        });
    }
}