export default class Header {
    constructor() {
        document.addEventListener("DOMContentLoaded", function (e) {
            var scrollpos = window.scrollY;
            var header = document.getElementById("header");

            function add_class_on_scroll() {
                header.classList.add("scrolled");
            }

            function remove_class_on_scroll() {
                header.classList.remove("scrolled");
            }

            if (header) { 
                window.addEventListener('scroll', function(){ 
                    //Here you forgot to update the value
                    scrollpos = window.scrollY;

                    if(scrollpos > 40){
                        add_class_on_scroll();
                    }
                    else {
                        remove_class_on_scroll();
                    }
                });
            }
        })
    }
}