import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class Gsap {
    constructor() {
        document.addEventListener("DOMContentLoaded", function (e) {
            gsap.registerPlugin(ScrollTrigger);

            gsap.utils.toArray("[data-in-view='block']").forEach((highlight) => {
              ScrollTrigger.create({
                trigger: highlight,
                start: "top bottom-=100px",
                onEnter: () => highlight.classList.add("is-in-view")
              });
            });

            gsap.utils.toArray("[data-in-view='img']").forEach((highlight) => {
                ScrollTrigger.create({
                  trigger: highlight,
                  start: "top bottom-=100px",
                  onEnter: () => highlight.classList.add("is-in-view")
                });
              });
        }); 
    }
}